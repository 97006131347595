import * as React from 'react'
import { forwardRef } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@rouvydev/web-components/utils'
import { Slot } from '@radix-ui/react-slot'

const tagVariants = cva(
  'relative inline-flex items-center rounded-[200px] px-6 py-3 text-body-14 font-semibold text-button-alternative-text-default transition-colors',
  {
    variants: {
      variant: {
        default:
          'bg-button-default-background-rest hover:bg-button-default-background-hover',
        outline:
          'border border-button-alternative-border-default bg-transparent text-button-alternative-text-default hover:border-button-alternative-border-hover hover:bg-button-alternative-background-hover active:border-button-alternative-border-active-(pressed) active:bg-button-alternative-background-active-(pressed)',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface TagProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof tagVariants> {
  asChild?: boolean
}

const Tag = forwardRef<HTMLButtonElement, TagProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(tagVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)

Tag.displayName = 'Tag'

export { Tag, tagVariants }
